import { render, staticRenderFns } from "./WordPressDialog.vue?vue&type=template&id=ab59942a"
import script from "./WordPressDialog.vue?vue&type=script&lang=js"
export * from "./WordPressDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Progetti\\abbondanza\\ebsn-front-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab59942a')) {
      api.createRecord('ab59942a', component.options)
    } else {
      api.reload('ab59942a', component.options)
    }
    module.hot.accept("./WordPressDialog.vue?vue&type=template&id=ab59942a", function () {
      api.rerender('ab59942a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/wordpress/WordPressDialog.vue"
export default component.exports